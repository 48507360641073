.loader {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  position: absolute;
  margin-bottom: 64px;
}

.content {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.loaderWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animationGroup {
  animation: rotate 1.6s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.logoIcon {
  overflow: visible;
  margin-top: 72px;
  opacity: 0.8;
}

.loadingText {
  margin-top: 16px;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .loaderWrap {
    height: 100%;
    margin-top: 64px;
    display: block;
  }
  .loader {
    left: 0;
    right: 0;
  }
}
