.button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 16px 56px;
  font-weight: 500;
  font-family: "Inter";
  box-sizing: border-box;
  white-space: nowrap;
  overflow: ellipsis;
  letter-spacing: 1px;
  transition: background-color 150ms ease-in-out;
  position: relative;
  font-size: 14px;
}

.button:hover:not(:disabled) {
  cursor: pointer;
}

.button:disabled {
  background-color: #cbcbcb;
}

.primary {
  color: white;
  background-color: var(--color-primary);
  font-weight: 700;
  text-transform: uppercase;
}

.primary:active:not(:disabled) {
  background-color: #00b8e6;
}

.secondary {
  color: var(--color-primary);
  border: 1px solid #eeeeee;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 16px;
  background: white;
}

.secondary:active:not(:disabled) {
  background-color: #fafafa;
}

.square {
  border-radius: 5px;
}

.pill {
  border-radius: 100px;
}

.fillWidth {
  width: 100%;
}

.destructive {
  color: var(--color-destructive);
}

.loaderBox {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.showLoader .children {
  visibility: hidden;
}

.showLoader .loaderBox {
  visibility: visible;
}
