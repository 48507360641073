.welcome {
  width: 100%;
  min-height: 100%;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo {
  margin-bottom: 24px;
  height: 42px;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 48px;
  box-shadow: var(--default-box-shadow);
  width: 500px;
  box-sizing: border-box;
}

.introText {
  text-align: center;
  line-height: 1.5;
  color: #616161;
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .welcome {
    padding: 1.5rem;
    box-sizing: border-box;
  }
  .logo {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 100%;
  }
  .content {
    width: 100%;
    padding: 0;
    box-shadow: none;
    align-items: start;
    background-color: transparent;
  }

  .inputsBox {
    width: 100%;
  }
  .nameInputs {
    flex-direction: column;
  }
  .introText {
    margin-bottom: 2rem;
    font-size: 1em;
    line-height: 2;
    margin-bottom: 4rem;
    text-align: center;
  }
}
