.body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: var(--color-background);
}

.title {
  font-size: 40px;
  margin: 0;
  margin-bottom: 8px;
}

.subtext {
  margin-bottom: 32px;
  text-align: center;
  font-size: 20px;
  color: #757575;
  margin: 0;
  line-height: 1.5;
}

.checkBox {
  height: 56px;
  width: 56px;
  background-color: #00cd90;
  border-radius: 50%;
  padding: 16px;
  margin-bottom: 24px;
}

.check {
  width: 100%;
  height: 100%;
}

.content {
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 96px 96px;
  border-radius: 10px;
  box-shadow: var(--default-box-shadow);
}

@media only screen and (max-width: 768px) {
  .content {
    background-color: transparent;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }

  .subtext {
    text-align: center;
  }
}
