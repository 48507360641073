.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 12px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #757575;
  line-height: 1;
  left: 8px;
  font-weight: 400;
  font-size: 16px;
}

.shiftedLabel {
  transform: translate(0, 5px) scale(0.7);
}

.inputContainer .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input {
  box-sizing: border-box;
  height: 56px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 150ms ease-in-out;
  border: 1px solid #cfcfcf;
  width: 100%;
  color: var(--color-black);
  font-family: "Inter";
  height: 40px;
  /* background: #f5f5f5; */
}

.inputFilled {
  padding: 24px 8px 10px 8px;
}

.input::placeholder {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.error {
  border: 1px solid #d53a4f;
}

.input:focus:not(.inputFilled):not(.error) {
  border: 1px solid var(--color-primary);
}
