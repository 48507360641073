.document {
  aspect-ratio: 0.77;
  height: max(90vh, 600px);
  overflow: overlay;
  overflow-x: hidden;
  border-radius: 7px;
  box-shadow: var(--default-box-shadow);
  flex-shrink: 0;
}

.pdfPage {
  margin-bottom: 16px;
}

.loading {
  width: 100%;
  height: max(90vh, 550px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .document {
    height: auto;
    overflow: visible;
    aspect-ratio: auto;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .pdfPage {
    box-shadow: var(--default-box-shadow);
    border-radius: 7px;
  }
}
