.confirmInformation {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-background);
}

.content {
  width: 550px;
  margin: 0 auto;
  background-color: white;
  padding: 48px;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
  box-shadow: var(--default-box-shadow);
}

.inputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-bottom: 16px;
}

.fullSpanInput {
  grid-column: 1 / 3;
}

.exit {
  position: absolute;
  right: 12px;
  top: 16px;
  background: 0;
  border: 0;
  cursor: pointer;
}

.networkError {
  color: var(--color-destructive);
  font-size: 14px;
  margin-top: 16px;
  text-align: start;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.title {
  margin-bottom: 8px;
  line-height: 1.3;
}

.subtext {
  text-align: center;
  line-height: 1.5;
  color: #616161;
  margin-bottom: 24px;
}

.checkboxes {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkbox {
  display: flex;
  gap: 8px;
  text-align: start;
  font-size: 14px;
  line-height: 1.5;
}

.loader {
  margin: 64px 0;
}

@media only screen and (max-width: 768px) {
  .confirmInformation {
    padding: 1rem;
    box-sizing: border-box;
    text-align: start;
  }
  .content {
    padding: 0;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
  .subtext,
  .content {
    text-align: start;
  }
}
