.progressBar {
  background-color: #e0e0e0;
  position: fixed;
  top: 0;
  height: 6px;
  width: 100%;
  z-index: 1;
}

.progressBarActive {
  background-color: var(--color-primary);
  height: 100%;
  transition: width 500ms ease-in-out;
}
