.consentSignature {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  padding: 5vh 7.5vw;
  box-sizing: border-box;
  gap: 5%;
}

.content {
  /* width: 750px; */
  display: flex;
  gap: 48px;
}

.pdfFrame {
  border-radius: 7px;
  height: 800px;
  width: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: var(--default-box-shadow);
  width: 50%;
}

.title {
  margin-bottom: 8px;
}

.inputBox input {
  width: 100%;
  border: 0;
  box-shadow: var(--default-box-shadow);
  padding: 14px;
  border-radius: 7px;
  font-size: 18px;
  box-sizing: border-box;
}

.inputBox input.hasError {
  border: 1px solid var(--color-destructive);
  outline: 1px solid var(--color-destructive);
}

.inputBox div:first-child {
  flex: 1;
}

.error {
  margin-top: 8px;
  font-size: 16px;
  color: var(--color-destructive);
  text-align: start;
}

.subtext {
  line-height: 1.5;
  color: #616161;
  margin-bottom: 24px;
}

.error {
  font-size: 14px;
  color: var(--color-destructive);
  margin-top: 8px;
}

.buttonWrap {
  margin-top: 32px;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 2em;
  }
  .consentSignature {
    flex-direction: column;
    padding: 1.5rem 1rem;
    margin-bottom: 70px;
    min-height: auto;
  }
  .formBox {
    width: 100%;
    margin-bottom: 16px;
  }
  .pdfFrame {
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
    box-shadow: none;
  }

  .mobilePdfLink {
    margin: 1.5rem 0;
  }

  .mobilePdfLink a {
    padding: 8px 16px;
    background-color: var(--color-background);
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    text-decoration: none;
    letter-spacing: 0.66px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    gap: 8px;
    color: var(--color-black);
  }

  .mobileLink a:active {
    background-color: #eeeeee;
  }
  .linkIcon {
    width: 16px;
    height: 16px;
    line-height: 50px;
  }

  .mobileSignBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    border-top: 1px solid #bdbdbd;
    z-index: 1;
  }

  .mobileSignBox input {
    width: 100%;
    border: 0;
    padding: 1rem;
    font-size: 18px;
    border-radius: 0;
    box-sizing: border-box;
  }

  .mobileSignBox button {
    background-color: var(--color-primary);
    border: 0;
    padding: 1rem 1rem;
    width: 64px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobileSignBox button:active {
    opacity: 0.75;
  }
  .mobileSignBox button[disabled],
  .mobileSignBox button:disabled {
    opacity: 0.5;
  }

  .mobileSignBox button img {
    width: 32px;
    height: 32px;
  }
}
