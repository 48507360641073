.identityConfirmation {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--color-background);
}

.content {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 48px;
  box-shadow: var(--default-box-shadow);
  width: 500px;
  box-sizing: border-box;
}

.title {
  text-align: center;
  margin-bottom: 16px;
  line-height: 1.3;
}

.subtext {
  text-align: center;
  line-height: 1.5;
  color: #616161;
  margin-bottom: 24px;
}
.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  text-align: start;
}

.inputs {
  display: flex;
  gap: 8px;
  width: 100%;
}

.inputs input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  font-weight: 500;
  min-width: 0;
}

.nameInputs input {
  flex: 1;
}

.inputsBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
}

.dayInput,
.monthInput {
  flex: 1;
}

.yearInput {
  flex: 2;
}

.inputsBox input.hasError {
  border: 1px solid var(--color-destructive);
}

.error {
  color: var(--color-destructive);
  margin-top: 8px;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .identityConfirmation {
    padding: 1.5rem;
    box-sizing: border-box;
  }
  .title {
    text-align: start;
  }
  .subtext {
    text-align: start;
  }
  .content {
    width: 100%;
    padding: 0;
    box-shadow: none;
    align-items: start;
    background-color: transparent;
  }
  .inputsBox {
    width: 100%;
  }
  .nameInputs {
    flex-direction: column;
  }
}
