.questionnaire {
  width: 100%;
  min-height: 100%;
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--default-box-shadow);
  width: 700px;
  padding: 48px;
  box-sizing: border-box;
}

.subtitle {
  line-height: 1.5;
  color: #616161;
  margin-bottom: 24px;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.checkbox {
  margin-right: 16px;
  scale: 1.25;
}

.question {
  margin-bottom: 32px;
}

.options {
  margin-left: 24px;
}

.householdSizeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.incomeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.options input {
  margin: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}

.options label {
  line-height: 2;
}

.questionLabel {
  line-height: 1.5;
  margin-bottom: 8px;
  font-weight: 600;
}

.title {
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 1.3;
  /* font-weight: 400; */
}

.buttonWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.error {
  color: var(--color-destructive);
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 2em;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .subtitle {
    font-size: 1em;
  }

  .questions {
    margin-bottom: 3rem;
    gap: 1rem;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    padding: 1rem;
    box-shadow: none;
  }
  .householdSizeGrid {
    grid-template-columns: 1fr 1fr;
  }
  .incomeGrid {
    grid-template-columns: 1fr;
  }
  .options {
    margin-left: 1rem;
  }
}
