@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap");
@import "./vars.css";

html {
  height: 100%;
  background-color: var(--color-background);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  font-family: "Inter";
  height: 100%;
}

h1,
h2,
h3,
p {
  margin: 0;
}

h1 {
  font-family: "Poppins";
}

#root {
  height: 100%;
}
